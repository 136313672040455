<template>
    <footer>
        <div>
        </div>
        <div class="container">
            <a href="https://www.software-by-design.de/" target="_blank" alt="Software by Design GbR"><img src="/assets/logo/sbd.png" class="float-left sbd-logo" height="40" width="40"></a>
            <div class="row">
                <div class="col-md-6">
                    <h5>Tripmeister</h5>
                    <p>&copy;{{currentYear}}<a href="https://www.software-by-design.de/" target="_blank" alt="Software by Design GbR"> Software by Design GbR</a></p>
                </div>
                <div class="col-md-6">
                    <ul class="footer-menu">
                        <li><router-link to="/impressum">Impressum</router-link></li>
                        <li><router-link to="/datenschutz">Datenschutz</router-link></li>
                        <li><button class="feedback btn" @click="showFeedbackModal">Feedback</button></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import { bus } from '@/main';

    export default {
        name: 'Footer',
        computed: {
            currentYear(){
                return new Date().getFullYear()
            },
        },
        methods: {
            showFeedbackModal() {
                bus.$emit('show-feedback-modal', true);
            },

        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    footer {
        position: fixed;
        width: 100%;
        padding: 25px 0;
        background: #333;
        color: #fff;
        z-index: -99;
        bottom: 0;

        @media (max-width: 991px) {
            display: none;
        }
        h5 {
            color: #fff;

            @media (max-width: 786px) {
                display: inline-block;
                margin-right: 10px;
            }
        }

        p {
            @media (max-width: 786px) {
                display: inline-block;
            }

            a{
              color:#fff;
          }
      }

      .footer-menu {
        float: right;
        margin-top: 15px;

        li {
            display: inline-flex;
            margin-left: 40px;

            a { 
                color: #fff;
                font-size: 14px;
                font-weight: 700;
            }

            @media (max-width: 400px) {
                margin-left: 15px;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        @media (max-width: 786px) {
            float: left;
            margin-top: 10px;
        }
    }

    @media (max-width: 786px) {
        padding: 30px 0;
    }

    .feedback {
        border: none;
    }
}

.sbd-logo {
    margin-right: 15px;
}
</style>
